.titleBar {
  background-color: #222831;
  margin: 0;
}

.titleBar h1 {
  margin: 0;
  padding: 4%;
  text-align: center;
  font-family: Julius Sans One;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "Julius Sans One", sans-serif;
  background: linear-gradient(180deg, #eeeeee 0% 25%, #0092CA 90% 100% );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 550px) {
    .titleBar h1 {
        font-size: 25px;
    }
}
@media screen and (max-width: 400px) {
    .titleBar h1 {
        font-size: 18px;
    }
}
