.footer {
  display: flex;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 4rem;
  margin-top: auto;
  background-color: #393e46;
  justify-content: space-between;
}

.navList {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 50%;
  margin-left: 2rem;
}

.nav {
  font-size: 1rem;
  font-family: "Archivo Narrow", sans-serif;
  margin: 1rem;
  color: #eeeeee;
  text-decoration: none;
}

.socials {
  display: flex;
  margin-right: 2rem;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}

.socials a {
  height: 100%;
  margin-inline: 25px;
  margin-block: 20px;
}

@media screen and (max-width: 825px) {
  .footer {
    flex-direction: column;
    height: fit-content;
    align-items: center;
  }

  .navList {
    margin: 0;
    justify-content: center;
  }

  .socials {
    margin: 0;
    justify-content: center;
  }

  .socials a {
    height: 70px;
  }
}

@media screen and (max-width: 400px) {
  .nav {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
}
