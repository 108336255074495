.description {
  background-color: #222831;
}

.description h1 {
  font-family: "Archivo Narrow", sans-serif;
  font-size: 25px;
  color: #eeeeee;
  margin-block: 0;
  margin-inline: 20px;
  padding-bottom: 10px;
}

.contactInfo {
  background-color: #eeeeee;
  padding: 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.contactInfo span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactInfo svg {
  margin: 5px;
}

.contactInfo p {
  font-family: "Julius Sans One", sans-serif;
}

.contactInfo a {
  font-family: "Julius Sans One", sans-serif;
  text-decoration: none;
  cursor: pointer;
}

.formWrapper {
  display: flex;
  justify-content: center;
  min-height: 1000px;
  background-color: #eeeeee;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-block: 50px;
  width: 60%;
  height: 500px;
}

.form :focus {
  outline: none;
}

.form label {
  color: #222831;
  text-align: left;
  width: 80%;
  margin: 5px;
  font-family: "Archivo Narrow", sans-serif;
}

.form input {
  height: 25px;
  width: 80%;
  border-radius: 3px;
  border: none;
  background-color: #ffffff;
  box-shadow: 3px 4px 5px #00000020;}

.form textarea {
  height: 50%;
  width: 80%;
  border-radius: 3px;
  border: none;
  background-color: #ffffff;
  box-shadow: 3px 4px 5px #00000020;}

.buttonWrapper {
  display: flex;
  width: 80%;
  align-items: flex-start;
  margin: 20px;
}

.buttonWrapper button {
  height: 40px;
  width: 125px;
  border-radius: 8px;
  border: none;
  background-color: #eeeeee;
  color: #27374d;
}

.buttonWrapper button {
  background-color: #526D82;
  color: #eeeeee;
  border-radius: 5px;
  box-shadow: 3px 4px 5px #00000020;
}
@media screen and (max-width: 1200px) {
  .form {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .description h1 {
    font-size: 20px;
  }

  .form {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .description h1 {
    font-size: 17px;
  }

  .form {
    width: 95%;
  }
}
@media screen and (max-width: 400px) {
  .description h1 {
    font-size: 15px;
  }

  .form {
    height: 300px;
  }
}
