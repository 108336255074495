.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 1%;
  background-color: #393e46;
}

.header img {
  height: auto;
  width: 28%;
  min-width: 210px;
}

.navList {
  position: absolute;
  right: 2rem;
}

.hamburgerMenu {
  position: absolute;
  /* top: 20px; */
  left: 20px;
  visibility: hidden;
}

.nav {
  font-size: 1rem;
  font-family: "Archivo Narrow", sans-serif;
  margin: 1rem;
  color: #eeeeee;
  text-decoration: none;
}

.socials {
  display: flex;
  margin-right: 2rem;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}

.socials a {
  height: 100%;
  margin-inline: 25px;
  margin-block: 20px;
}

@media screen and (max-width: 1000px) {
  .header img {
    width: 50%;
    min-width: 125px;
  }
  .navList {
    visibility: hidden;
  }
  .nav {
    font-size: 0.75rem;
    margin-block: 3px;
  }
  .hamburgerMenu {
    visibility: visible;
  }
}

@media screen and (max-width: 400px) {
  .header img {
    margin-left: 10px;
    min-width: 95px;
  }
}
