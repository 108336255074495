.menu {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  height: 200px;
  width: 200px;
  background-color: #dde6ed;
  flex-direction: column;
  justify-content: center;
  border-bottom-right-radius: 100%;
  box-shadow: 2px 3px 4px #5e5e5e40;
}

.nav {
  font-size: 1rem;
  font-family: "Archivo Narrow", sans-serif;
  text-align: left;
  margin: 1rem;
  color: #27374d;
  text-decoration: none;
}

@media screen and (max-width: 500px) {
  .menu {
    height: 125px;
    width: 125px;
  }
  .nav {
    margin-block: 5px;
    font-size: .75rem;
  }
}